import React from 'react';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/layout';

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  margin-top: 1rem;
`;

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Container className="columns is-vcentered">
      <div className="column is-narrow">
        <ImgContainer>
          <Img fixed={data.file.childImageSharp.fixed} />
        </ImgContainer>
      </div>
      <div className="column">
        <h1>About Me</h1>
        <p>
          Welcome! I'm Chris, a software engineer with a passion for
          fermentation, currently living in Pittsburgh, PA. I started
          homebrewing when I turned 21 and have been baking bread for a little
          over 5 years. I created this site to share experiences from my
          fermentation projects and the lessons I have learned along the way.
          Cheers!
        </p>
      </div>
    </Container>
  </Layout>
);

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "avatar.png" }) {
      childImageSharp {
        fixed(width: 225, height: 225) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;
